body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #F2F2F6;
}

.active,
.item-hilight:hover {
    background-color: #007aff;
    color: #FEFEFE;
}

/* Begin Text Definitions */
.service-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.service-heading {
    color: black;
    font-size: 1.125rem;
    font-weight: bold;
}

.service-subheading {
    color: black;
    font-size: 1.0rem;
    font-weight: bold;
}

.service-bodytext {
    color: black;
    font-size: 0.875rem;
    line-height: 0.75rem;
    font-weight: normal;
}

.service-footnote {
    color: black;
    font-size: 0.825rem;
    line-height: 0.825rem;
    font-style: italic;
}


.service-form-label {
    color: black;
    font-size: 0.75rem;
    line-height: .375rem;
    font-weight: normal;
}

.service-check-label {
    color: black;
    font-size: 0.75rem;
    line-height: .375rem;
    /* font-weight: 600; */
}

.employee-label,
.employee-text {
    font-size: 0.875rem;
    font-weight: normal;
}

.employee-label {
    font-weight: 600;
}

.employee-name {
    font-size: 1.0rem;
    font-weight: 600;
}

.select-disabled {
    color: #6e757c;
}

.assignment-log-text {
    color: black;
    font-size: 0.825rem;
    /* line-height: .825rem; */
    text-align: left;
    /* font-weight: 600; */
}

/* End Text Definitions */




/* Begin Button Definitions */
.button-standard-primary,
.button-standard-secondary {
    background-color: #007aff;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 44px;
    border: none;
    border-radius: 9px;
    color: #FEFEFE;
    padding: 0px 32px;
    vertical-align: center;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    margin: 4px 2px;
    cursor: pointer;
}

.button-standard-secondary {
    background-color: #EEEEEF;
    font-weight: normal;
}

.button-pill,
.button-pill-small,
.button-pill-caution,
.button-pill-small-caution {
    background-color: #EEEEEF;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 30px;
    border: none;
    border-radius: 22px;
    /* color: black; */
    padding: 0px 18px;
    vertical-align: center;
    text-align: center;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    color: #007aff;
    margin: 4px 2px;
    cursor: pointer;
}

.button-pill-small,
.button-pill-small-caution {
    height: 24px;
}

.button-pill-caution,
.button-pill-small-caution {
    color: #FF3B30;
}

.button-max-primary,
.button-max-secondary {
    background-color: #007aff;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 44px;
    width: 100%;
    border: none;
    border-radius: 9px;
    color: #FEFEFE;
    padding: 0px 0px;
    vertical-align: center;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    margin: 4px 2px;
    cursor: pointer;
}

.button-max-secondary {
    background-color: #EEEEEF;
    font-weight: normal;
    color: black;
}


button:disabled,
button[disabled] {
    cursor: default;
    background-color: #eaecef;
    color: #666666;
}

.img-disabled {
    filter: grayscale(100%);
}

.form-select:disabled {
    cursor: default;
    background-color: #FEFEFE;
    color: #000000;
}


/* End Button Definitions */


/* Begin Card Definitions */
.card {
    background: #FEFEFE;
    border-radius: 9px;
    border-color: lightgray;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    overflow: auto;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 1180px;
}


.freeze-pane {
    overflow: hidden;
}

/* End Card Definitions */



.ST-navbar {
    background: #FEFEFE;
    border-radius: 0px;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
}

.ST-bottom-navbar {
    background: #FEFEFE;
    border-radius: 0;
    min-height: 40px;
}

.ST-menu-icon {
    margin-right: "30px"

}


.list-group-scroll {
    max-height: 200px;
    min-height: 200px;
    margin-bottom: 10px;
    overflow: scroll;
    border: 1px;
}

.list-group-scroll-reports {
    max-height: 100vh;
    overflow-y: auto;
    margin-bottom: 100px;
    border: 1px;
}

.form-date-sm {
    height: 39 px;
    font-size: 0.875rem;
    /* padding-bottom: 4px; */
}

.save-changes {
    margin: 0 !important;
    padding: 0 !important;
}

#save-changes-modal {
    vertical-align: middle;
    align-self: center;
    margin-top: 30vh;
}

.pdfPreview {
    text-align: center;
    height: 80vh;
}

.downloading {
    text-align: center;
    /* height: 10vh; */
}

.spinner {
    margin-top: 35vh;
    text-align: center;
}

.spinner-mobile {
    text-align: center;
}

.left {
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .desktop-card {
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    .mobile-card {
        display: none;
    }
}

@media screen,
print {

    #content,
    #page {
        width: 100%;
        margin: 0;
        float: none;
    }

    /* footer {page-break-after: always;}

    @bottom-right {
        content: counter(page) " of " counter(pages);
      }
    @page {
        margin: 2cm;

    } */

    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        color: #000;
    }

    .card {
        background: #ffffff;
        border-radius: 0px;
        border-width: 0px;
        border-color: silver;
        /* border-color: black; */
        /* These measurements with a style="margin-top:24px" in line give 8.5x11 with 0.5" margins */
        /* max-width: 672px; */
        /* max-height: 892px; */
        /* min-height: 892px; */

        /* These measurements with a style="margin-top:48px" in line give 8.5x11 with 0.75" margins */
        /* max-width: 624px;
        max-height: 844px; */
    }



    .report-title {
        font-size: 1.0rem;
        font-weight: bold;
        text-align: center;
    }

    .report-subhead {
        font-size: 0.875rem;
        font-weight: 600;
    }

    .report-label {
        font-size: 0.875rem;
        font-weight: 600;
    }

    .report-value {
        font-size: 0.875rem;
        font-weight: normal;
    }

    .report-text {
        font-size: 0.875rem;
        font-weight: normal;
        font-style: normal;
        line-height: 0.875rem;
    }

    .report-text-ital {
        font-size: 10px;
        font-weight: normal;
        font-style: italic;
        line-height: 0.875rem;
    }

    .report-subhead-md {
        font-size: 1.0rem;
        font-weight: 600;
    }

    .report-label-md {
        font-size: 0.875rem;
        font-weight: 600;

    }

    .report-value-md {
        font-size: 0.875rem;
        font-weight: normal;

    }

    .report-text-md {
        font-size: 0.875rem;
        font-weight: normal;
        font-style: normal;
        line-height: 0.875rem;
    }

    .report-text-ital-md {
        font-size: 0.875rem;
        font-weight: normal;
        font-style: normal;
        line-height: 0.875rem;
    }
}

.content-routes {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding-top: 52px !important;
}

.search-bar {
    margin-top: 10px;
}

.login-wrapper {
    padding: 18px;
    margin: 0 auto;
    position: fixed;
    border-radius: 6px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    min-width: 250px;
    transform: translate(-50%, -50%);
}